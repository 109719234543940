interface PageProps {
  cssClasses?: string;
  id: string;
}

export const Page: React.FC<PageProps> = (props) => (
  <div
    id={props.id}
    className={`bg-white min-h-full md:px-40 xl:px-64 2xl:px-80 ${props.cssClasses}`}
  >
    {props.children}
  </div>
);
