interface CardProps {
  cssClasses?: string;
}

export const Card: React.FC<CardProps> = (props) => (
  <div
    className={`bg-white xl:mx-5 rounded-xl p-8 border border-blue-600 border-r-2 border-b-2 ${props.cssClasses}`}
  >
    {props.children}
  </div>
);
