import { SetStateAction, useState } from "react";
import { ContactModal } from "./modals/ContactModal";
import { About } from "./pages/AboutPage";
import { ExperiencePage } from "./pages/ExperiencePage";
import { SkillsAndEducationPage } from "./pages/SkillsAndEducationPage";
import { AiOutlinePhone } from "react-icons/ai";
import { Waypoint } from "react-waypoint";
import { Header } from "./components/Header";

function App() {
  const [modalVisible, setModalVisible] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);

  return (
    <>
      {buttonVisible && (
        <button
          onClick={() => setModalVisible(true)}
          className={`flex items-center justify-center fixed bottom-5 right-5 w-14 h-14
               bg-gray-200 border-blue-600 border-2 text-blue-800 
                focus:outline-none rounded-full`}
        >
          <AiOutlinePhone size={30} />
        </button>
      )}
      <Header />
      <ContactButtonWaypoint setButtonVisible={setButtonVisible} />
      <About openModal={() => setModalVisible(true)} />
      <ExperiencePage />
      <SkillsAndEducationPage />
      <ContactModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </>
  );
}

const ContactButtonWaypoint = (props: {
  setButtonVisible: (val: SetStateAction<boolean>) => void;
}) => (
  <Waypoint
    topOffset={-120}
    bottomOffset={120}
    onEnter={() => props.setButtonVisible(false)}
    onLeave={() => props.setButtonVisible(true)}
    onPositionChange={({ currentPosition }) =>
      currentPosition === "above" && props.setButtonVisible(true)
    }
  />
);

export default App;
