import { Card } from "../components/Card";
import { Collapsible } from "../components/Collapsible";
import { Heading } from "../components/Heading";
import { Page } from "../components/Page";
import { RiSuitcaseLine } from "react-icons/ri";

export const ExperiencePage = () => (
  <Page
    id="page-experience"
    cssClasses="pt-40 flex justify-center items-center"
  >
    <Card cssClasses="w-full  flex-grow-0">
      <Heading>
        <RiSuitcaseLine />
        <div className="ml-2">Experience</div>
      </Heading>
      <div className="mt-8">
        <Collapsible
          logo="https://d1yjjnpx0p53s8.cloudfront.net/styles/logo-thumbnail/s3/0025/3835/brand.gif?itok=vqhXeL23"
          title="Jabil Circuit Pvt. Ltd."
          subTitle="Oct 2019 - Till date"
          initialOpen
        >
          {jabilMarkup}
        </Collapsible>
      </div>
      <div className="mt-8">
        <Collapsible
          logo="http://www.hitechmarketing.co.in/wp-content/uploads/2017/12/rishabh.png"
          title="Rishabh Instruments Pvt. Ltd."
          subTitle="Jan 2011 - Sept 2019"
          initialOpen={false}
        >
          {rishabhMarkup}
        </Collapsible>
      </div>
      <div className="mt-8">
        <Collapsible
          logo="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1Sne7eZVXHvf-RqJs30HMd1MBo7TEwBPGSQ&usqp=CAU"
          title="Brains Innovations"
          subTitle="Aug 2010 - Dec 2010"
          initialOpen={false}
        >
          {brainsInnovationsMarkup}
        </Collapsible>
      </div>
    </Card>
  </Page>
);

const jabilListItems = [
  "Spearheading production of consumer electronics devices for an American client. Handling team size of 250 members including white and blue collar members. ",
  "Setting up business unit since its inception; working through New Product Introduction, design verification, process verification and mass production phases ",
  "Leading Manufacturing Operations and working with cross functional teams like Production Engineering, Supply Chain & Procurement, Cost Engineering & Management, Sourcing & Procurement, New Product Development and Process Engineering ",
  "Managing different cross-functional teams for setting up additional resources including tools, fixtures, equipment, manpower for the same ",
  "Driving improvement in business performance through cost reduction in direct and indirect areas, procurement transformation, supply chain optimization, business and operations strategy, and re-engineering ",
  "Leveraging research, analytics and industry insights to create value for clients ",
  "Formulating new solutions, frameworks, models, methodologies, tools and techniques in alignment with market and client needs ",
  "Providing thought leadership and direction around core skills during project delivery ",
  "Ramped up the production from 50 units per day to 5000 units per day ",
  "Working at Lead position through inception, ramp-up and mass production phases.",
];

const jabilMarkup = (
  <div>
    <div className="text-xl font-bold">Manufacturing Lead</div>
    <div className="font-bold">
      Manufacturing Lead for a Consumer Electronics Manufacturing Business Unit
    </div>
    <div>
      Responsible for production of consumer electronics devices for an American
      client contributing to USD 1.8 Million (INR 13 Crores) Monthly revenue.
    </div>

    {jabilListItems.map((item) => (
      <li>{item}</li>
    ))}
  </div>
);

const risabhPositionOne = [
  "Head of Analog Panel Meters and Protective Trip Relays Manufacturing Business Unit",
  "Steered 8 production lines contributing to approx. 600 Mn INR of turnover annually",
  "Attained production targets and maintain on time delivery and quality performance for domestic and overseas customers",
  "Administered budget and financial planning for the business unit including capital expenditures, annual maintenance costs, consumables, travel expenses, and manpower",
  "Supervised key customer account management activities including order prioritization, planning, quality improvements, and customer complaints",
  "Interacted with PPC and Procurement sections to ensure accurate planning and availability of raw material on all production lines",
  "Led initiatives like process automation, process modifications &process outsourcing",
  "Monitored quality improvement initiatives like RCA, FMEA and QA Audits",
  "Conducted HSE Audits, Safety Mock Drills",
  "Participated in external ISO QMS Audits and conducted internal ISO QMS Audits",
];

const risabhPositionTwo = [
  "Steered operations for key customer accounts TE Connectivity (USA, UK, Singapore, Hong Kong & Australia) and Sifam Tinsley Instrumentation (UK)",
  "Collaborated with QA for complaints resolution and submission of CAPA & 8Dy",
  "Managed ISO 9001:2008 related activities in manufacturing section to ensure that the activities are carried out as per the process manuals and to monitor the defined KPIs",
  "Faced the external audits and facilitated the external auditors during the surveillance and re-certification audits",
  "Established the Current Transformers section and ramped up capacity threefold",
  "Worked as a Project Manager for development of an IoT based automatic inspection system for conformance testing of analog panel meters in collaboration with a partner software company",
];

const risabhPositionThree = [
  "Managed TE Connectivity Worldwide Account consisting of 7 locations viz. USA, UK, Germany, Hong Kong, Singapore, Australia and Dubai",
  "Successfully led and completed the time study for 10 production sections consisting of 300 workmen",
  "Completed the Dial Plotting Software Development Project",
];

const risabhPositionFour = [
  "Worked in USA in TE Connectivity for 2 months (Oct to Dec 2011) on ANSI Long Scale Meters Project",
];

const risabhPositionFive = [
  "Set up the Production line for an Electrical Analyzer Device for Germany based BENDER GmbH",
  "Active Member of Quality Improvement Task Force “Arunodaya”",
];

const rishabhMarkup = (
  <div>
    <div className="text-xl font-bold">
      Assistant Manager - Manufacturing Operations
    </div>
    {risabhPositionOne.map((item) => (
      <li>{item}</li>
    ))}
    <div className="mt-5 text-xl font-bold">Senior Executive (Operations)</div>
    {risabhPositionTwo.map((item) => (
      <li>{item}</li>
    ))}
    <div className="mt-5 text-xl font-bold">Executive (Operations)</div>
    {risabhPositionThree.map((item) => (
      <li>{item}</li>
    ))}
     <div className="mt-5 text-xl font-bold">Junior Executive (Operations)</div>
    {risabhPositionFour.map((item) => (
      <li>{item}</li>
    ))}
    <div className="mt-5 text-xl font-bold">Management Trainee (Operations)</div>
    {risabhPositionFive.map((item) => (
      <li>{item}</li>
    ))}
  </div>
);

const brainsInnovationsListItems = [
  "Worked as R&D Engineer in Manufacturers of Wireless Security Systems, Fire Detection Systems, Smoke Sensor Systems",
  "Steered development of Home Automation System",
  "Led manufacturing processes and troubleshooting activities",
];

const brainsInnovationsMarkup = (
  <div>
    <div className="text-xl font-bold">R&D Engineer</div>
    {brainsInnovationsListItems.map((item) => (
      <li>{item}</li>
    ))}
  </div>
);
