import { HiOutlineLightBulb } from "react-icons/hi";
import { BulletList } from "../components/BulletList";
import { Card } from "../components/Card";
import { Heading } from "../components/Heading";

export const SkillsCard: React.FC = () => (
  <Card cssClasses=" w-full 2xl:w-1/3">
    <Heading>
      <HiOutlineLightBulb size={25} className="mr-2" /> Skills
    </Heading>
    <div className="mt-5">
      <BulletList
        title="Manufacturing Operation Skills"
        listItems={operationSkills}
      />
      <BulletList
        title="Manufacturing Quality Skills"
        listItems={qualitySkills}
      />
      <BulletList title="Computer/ERP Skills" listItems={computerSkills} />
      <BulletList title="Personal Skills" listItems={personalSkills} />
    </div>
  </Card>
);

const operationSkills = [
  "Productivity Enhancement",
  "Key Account Management",
  "Process Automation",
  "Process Improvements",
  "Production Planning and Control",
  "Manpower Handling, Team Management",
  "ISO 9001 : 2015",
  "Process Establishment",
  "Vendor Development",
  "5S, Kaizen, Spaghetti Charts, Value Stream Mapping",
  "Health and Safety",
];

const qualitySkills = [
  "7 QC Tools",
  "Pareto Analysis",
  "Fish – bone diagrams",
  "Root Cause Analysis",
  "8D methodology",
  "FMEA",
  "Poka-Yoke",
];

const computerSkills = [
  "SAP End User",
  "Microsoft Office",
  "Manufacturing Execution Systems",
];

const personalSkills = [
  "Good Soft Skills viz. Verbal and Written communication, Presentation skills. ",
  "Ability to work as a good and effective team leader. ",
  "Effective handling of work in high pressure situations.",
];
