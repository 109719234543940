import { MouseEventHandler } from "react";
import { Modal } from "../components/Modal";
import { FiLinkedin, FiMail, FiPhone } from "react-icons/fi";

interface ContactModalProps {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
}

export const ContactModal: React.FC<ContactModalProps> = (props) => (
  <Modal
    title="Contact"
    isVisible={props.modalVisible}
    onCloseClicked={() => props.setModalVisible(false)}
  >
    <div className="mt-8">
      <ContactTile
        icon={<FiPhone size={25} />}
        value={<div className="text-xl">+91 9021484395</div>}
      />
      <ContactTile
        icon={<FiMail size={25} />}
        value={
          <div className="text-xl">
            <a href="mailto:nikhil.medhe@gmail.com">nikhil.medhe@gmail.com</a>
          </div>
        }
      />
      <ContactTile
        icon={<FiLinkedin size={25} />}
        value={
          <div className="text-xl">
            <a href="http://in.linkedin.com/in/nikhilmedhe" target="_blank">
              /nikhilmedhe
            </a>
          </div>
        }
      />
    </div>
  </Modal>
);

interface ContactTileProps {
  icon: JSX.Element;
  value: JSX.Element;
}
const ContactTile: React.FC<ContactTileProps> = (props) => (
  <div className="mb-3 bg-gray-200  text-blue-600 py-3 px-5 rounded-full flex items-center">
    <div className="mr-3">{props.icon}</div>
    <div>{props.value}</div>
  </div>
);
