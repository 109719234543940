import { ButtonHTMLAttributes } from "react";

export const Button: React.FC<ButtonHTMLAttributes<any>> = (props) => (
  <button
    onClick={props.onClick}
    className="bg-gray-300 text-gray-700 font-bold hover:shadow-md focus:shadow-none border-2 border-gray-bg-gray-500 focus:outline-none py-1 px-4 rounded-xl"
  >
    {props.children}
  </button>
);
