import { MouseEventHandler } from "react";
import { Button } from "./Button";
import { Card } from "./Card";
import { FiX } from "react-icons/fi";

interface ModalProps {
  title: string;
  isVisible: boolean;
  onCloseClicked: MouseEventHandler;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { isVisible, onCloseClicked, title } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 h-full w-full bg-black bg-opacity-60 flex items-center justify-center">
      <Card cssClasses="xl:w-1/2">
        <div className="flex justify-between">
          <div className="text-2xl">{title}</div>
          <Button onClick={onCloseClicked}>
            <FiX />
          </Button>
        </div>
        {props.children}
      </Card>
    </div>
  );
};
