import React from "react";
import { Link } from "react-scroll";
import logoPath from "../assets/logo.png";

export const Header: React.FC = () => (
  <div
    className={`header__content flex w-full justify-start xl:justify-center fixed top-0 left-0 
    bg-white border-b-2 bg-opacity-80 border-blue-600 overflow-x-auto`}
    style={{
      zIndex: 10000,
      opacity: 1,
      transition: "all .6s",
    }}
  >
    <div className="flex items-center px-6 py-5 xl:py-3">
      <div className="font-bold text-lg text-blue-600 flex-shrink-0 mr-5 xl:mr-8">
        Nikhil G. Medhe
      </div>
      <NavLink to="page-about">About</NavLink>
      <NavLink to="page-experience">Experience</NavLink>
      <NavLink to="page-education-and-skills" className="pr-10">
        Education & Skills
      </NavLink>
    </div>
  </div>
);

interface INavLinkProps {
  to: string;
  className?: string;
}

const NavLink: React.SFC<INavLinkProps> = ({ to, children, className }) => (
  <Link
    className={`text-blue-600 mr-5 xl:mr-8 cursor-pointer inline-block flex-shrink-0 ${className}`}
    to={to}
    spy
    smooth
    activeClass="underline"
    offset={-100}
  >
    {children}
  </Link>
);
