interface BulletListProps {
  title?: string;
  listItems: string[];
}

export const BulletList: React.FC<BulletListProps> = (props) => (
  <div className="mb-5">
    {props.title && <div className="text-xl font-bold">{props.title}</div>}
    {props.listItems.map((item) => (
      <li>{item}</li>
    ))}
  </div>
);
