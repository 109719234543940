import { LinkHTMLAttributes } from "react";

export const Link: React.FC<LinkHTMLAttributes<any>> = (props) => {
  return (
    <a
      className="ml-4 text-gray-500 font-bold"
      href={props.href}
      target="_blank"
    >
      {props.children}
    </a>
  );
};
