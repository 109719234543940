import { ImClipboard } from "react-icons/im";
import { BulletList } from "../components/BulletList";
import { Card } from "../components/Card";
import { Heading } from "../components/Heading";
import { Page } from "../components/Page";
import { SkillsCard } from "./SkillsCard";
import { FiAward } from "react-icons/fi";
import { FaUniversity } from "react-icons/fa";

export const SkillsAndEducationPage = () => (
  <Page id="page-education-and-skills" cssClasses="py-40 flex flex-col 2xl:flex-row justify-center">
    {credentialsRow()}
    <SkillsCard />
  </Page>
);

const credentialsRow = () => (
  <Card cssClasses=" w-full 2xl:w-2/3 mb-10 2xl:mb-0">
    <Heading>
      <ImClipboard className="mr-2" /> Trainings
    </Heading>
    <BulletList listItems={trainingsList} />

    <div className="my-8 border-t border-gray-600"></div>
    <Heading>
      <FiAward className="mr-2" /> Awards
    </Heading>
    <BulletList listItems={awardsList} />

    <div className="my-8 border-t border-gray-600"></div>
    <Education />
  </Card>
);

const trainingsList = [
  "Relationship Management Program by Mr. Homi Mulla",
  "IRCA Certified ISO 9001 : 2008 Lead Auditor Course",
  "Internal Auditor course for ISO 9001 : 2008 QMS",
  "Time Study Methodology by Pune Divisional Productivity Council",
  "Seminar on Problem Solving, 8D, CAPA at TE Connectivity, Pune",
  "Introductory program for Six-Sigma",
  "3 day Seminar on Soft skills development. 2 day Seminar on ISO 14001 EMS",
  "Lean Management Trainings",
];

const awardsList = [
  "Winner of CEO’s Award for Development and implementation of IoT Based Automatic Testing System",
  "IRCA Certified ISO 9001 : 2008 Lead Auditor",
];

const Education = () => (
  <>
    <Heading>
      <FaUniversity className="mr-2" /> Education
    </Heading>
    <div className="font-bold">
      Post Graduate Diploma in Business Administration - Operations
    </div>
    <div className="text-gray-500">Symbiosis Center for Distance Learning</div>
    <div className="text-green-600 font-bold">Secured First Class</div>

    <div className="font-bold mt-5">Bachelor of Engineering - Electronics</div>
    <div className="text-gray-500">University of Pune</div>
    <div className="text-green-600 font-bold">
      Secured First Class with Distinction
    </div>
  </>
);
