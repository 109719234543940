import { useState } from "react";
import { GrDown, GrUp } from "react-icons/gr";

interface CollapsibleProps {
  initialOpen: boolean;
  title: string;
  subTitle: string;
  logo?: string;
}

export const Collapsible: React.FC<CollapsibleProps> = (props) => {
  const [isOpen, setIsOpen] = useState(props.initialOpen);

  return (
    <div className="rounded-xl border-blue-300 border">
      <div
        className={`p-5 bg-blue-50 rounded-t-xl flex items-center justify-between cursor-pointer select-none ${
          isOpen ? "rounded-t-xl" : "rounded-xl"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <img src={props.logo} className="w-12 h-12 rounded mr-5" />
          <div className="flex flex-col">
            <div className="text-xl text-blue-600 font-bold">{props.title}</div>
            <div className="text-md text-gray-400 font-bold">{props.subTitle}</div>
          </div>
        </div>
        <div>{isOpen ? <GrUp /> : <GrDown />}</div>
      </div>

      {isOpen && (
        <div className="bg-white p-5 rounded-b-xl">{props.children}</div>
      )}
    </div>
  );
};
