import dp from "../assets/dp.jpg";
import graph from "../assets/graph.png";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { Heading } from "../components/Heading";
import { Page } from "../components/Page";
import { MouseEventHandler } from "react";
import { Link } from "../components/Link";

import { RiPagesLine, RiMapPin2Line } from "react-icons/ri";
import { BiTrendingUp } from "react-icons/bi";

interface AboutProps {
  openModal: MouseEventHandler;
}

export const About: React.FC<AboutProps> = (props) => {
  return (
    <Page id="page-about" cssClasses="flex justify-center">
      <Card cssClasses="flex flex-col mt-20 xl:mt-32">
        <div className="flex flex-wrap">
          <div className="xl:border-r mb-4 xl:mb-0 border-gray-500 pr-8">
            <img src={dp} className="h-32 w-32 rounded-xl" alt="" />
          </div>

          <div className="xl:pl-8">
            <div className="text-3xl">Nikhil Medhe</div>
            <div className="text-xl text-blue-600 font-bold">
              Manufacturing Operations Professional
            </div>
            <div className="flex items-center">
              <RiMapPin2Line />
              <span className="ml-1">Pune, Maharashtra</span>
            </div>

            <div className="mt-4">
              <Button onClick={props.openModal}>Contact</Button>
              <Link href="https://aditya-medhe-portfolio-bucket.s3.ap-south-1.amazonaws.com/nikhil-medhe-resume.pdf">
                Download Resume
              </Link>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-500 mt-5 py-8">
          <Heading>
            <RiPagesLine /> <div className="ml-2">Summary</div>
          </Heading>
          <div>
            {summaryLines.map((line) => (
              <li>{line}</li>
            ))}
          </div>
        </div>

        <div className="border-t border-gray-500 mt-5 py-8">
          <Heading>
            <BiTrendingUp /> <div className="ml-2">Career Graph</div>
          </Heading>
          <div className="flex justify-center">
            <img src={graph} className="xl:w-8/12" alt="" />
          </div>
        </div>
      </Card>
    </Page>
  );
};

const summaryLines = [
  "A competent professional with over 11 years of experience in Manufacturing Operations including Production, Key Account Management, Quality, Engineering, Supply Chain Management and Logistics Operations",
  "Vast experience in Production, Maintenance, Manufacturing Excellence, Safety, Quality Assurance and Team Management",
  "Expert in production planning activities right from the conceptualization stage to the mass production activities.",
  "IRCA Certified ISO 9001:2008QMS Lead Auditor; keen insights on 7 QC Tools, Pareto Analysis, Fishbone diagrams, RCA, 8D methodology, FMEA and Poka-Yoke, Knowledge of 5S, Kaizen, Spaghetti Charts, Value Stream Mapping",
  "Drove SAP implementation as a member of the Support Team",
  "Multi-cultural exposure across USA, UK, Poland, Singapore & Australia; worked in TE Connectivity, USA for two months for project stabilization",
  "Successfully developed an IoT based automatic inspection system for conformance testing of analog panel meters in collaboration with partner a software company; won CEO’s Award for the same",
  "Established new production line for a consumer electronics device for an American client and run mass production for same",
  "Set up new production line for 3 phase power quality analyzer device",
  "Led the Industrial Engineering Department consisting of 5 Engineers",
  "Proven track record in coordinating, planning & implementing changes to technology, machinery & equipment, process methodology, direction of quality assurance and control systems & reporting procedures",
  "An excellent communicator with interpersonal, analytical & problem-solving skills",
];
